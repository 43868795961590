<template>
  <van-popup v-model:show="showBox"
             style="border-radius: 4px;">
    <div class="select-source-type">
      <div class="b1">选择消息类型</div>
      <div class="b2">
        <div v-for="(item,index) in list1"
             :key="index"
             class="item flex"
             @click="itemClick(index)">
          <!-- 	<van-uploader :after-read="afterRead" v-if="index==1">
              <div class="flex" style="width: 235px;">
                <div><div class="icon-add"></div></div>
                <div class="flex-1 p1">{{item.name}}</div>
                <div><div class="icon"><img :src="item.icon"></div></div>
              </div>
            </van-uploader> -->
          <!-- 	<div v-if="index!=1"><div class="icon-add"></div></div>
            <div v-if="index!=1" class="flex-1 p1">{{item.name}}</div>
            <div v-if="index!=1"><div class="icon"><img :src="item.icon"></div></div> -->

          <div>
            <div class="icon-add" />
          </div>
          <div class="flex-1 p1">{{ item.name }}</div>
          <div>
            <div class="icon"><img :src="item.icon"></div>
          </div>
          <input class="file"
                 type="file"
                 accept="image/*"
                 id="uploadCaptureInputFile"
                 @change="uploadImg"
                 v-if="item.name==='图片'">
        </div>
      </div>

      <div class="b3">
        <button class="btn"
                @click="cancel">取消</button>
      </div>
    </div>
  </van-popup>
</template>

<script>

import { mapGetters } from 'vuex'
import { ossUploadFile } from '@/utils/index.js'
import img1 from './assets/icon-1.png'
import img2 from './assets/icon-2.png'
import img3 from './assets/icon-3.png'
import img4 from './assets/icon-4.png'
export default {
  name: 'Index',
  props: {
    data: Array,
    show: {
      type: Boolean,
      default: false
    },
    showMain: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showBox: this.show,
      list: [
        { icon: img1, name: '文本' },
        { icon: img2, name: '图片' },
        { icon: img3, name: '链接' },
        { icon: img4, name: '我的店铺首页' }
      ],
      list1: [
        { icon: img1, name: '文本' },
        { icon: img2, name: '图片' },
        { icon: img3, name: '链接' }
      ],
      eIndex: 0
    }
  },
  computed: {
    ...mapGetters(['globalData'])
  },
  watch: {
    show (v) {
      this.showBox = v
    }
  },
  activated () {
    const pick = this.globalData
    let data = null
    console.log('我是globalData：', pick)
    if (pick.text) {
      data = {
        msgType: 2001,
        msgContent: pick.text,
        isAtNewcomer: pick.showAt || false
      }
    } else if (pick.link) {
      data = {
        msgType: 2005,
        msgContent: pick.link.msgContent,
        msgTitle: pick.link.msgTitle,
        msgDes: pick.link.msgDes,
        msgHref: pick.link.msgHref
      }
    } else if (pick.miniapp) {
      data = pick.miniapp
    }
    if (data) {
      if (pick.isEdit) {
        this.data.splice(pick.editIndex, 1, data)
      } else {
        this.data.push(data)
      }
      this.$store.commit('resetGlobalData')
    }
  },
  methods: {
    cancel () {
      this.showBox = false
      this.$emit('close')
    },
    async uploadImg (e) {
      console.log(e)

      if (e.target.files.length) {
        const file = e.target.files[0]

        const pick = this.globalData
        setTimeout(() => {
          console.log(111)
          document.getElementById("uploadCaptureInputFile").value = "";
        }, 1000)
        ossUploadFile(file,1 ).then(res => {
          if (res) {
            if (pick.isEdit) {
              this.data.splice(pick.editIndex, 1, {
                msgType: 2002,
                msgContent: res
              })
            } else {
              this.data.push({
                msgType: 2002,
                msgContent: res
              })
            }
            this.$emit('close', this.eIndex, res)
          }
        })

      }
    },
    itemClick (index) {
      this.eIndex = index

      if (index === 0) {
        // 文字
        this.$store.commit('setGlobalData', {
          showAt: true
        })
        this.$router.push('/editText')
      } else if (index === 1) {
        // 图片
      } else if (index === 2) {
        // 链接
        this.$router.push('/editLink')
      } else if (index === 2) {
        // 素材库
        this.$router.push('/editMiniapp')
      }

      this.showBox = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.select-source-type {
  width: px(295);
  height: px(361);
  background: #ffffff;
  border-radius: px(4);
  .b1 {
    padding: px(20);
    text-align: center;
    font-size: px(16);
    font-weight: bold;
  }
  .b2 {
    padding: 10px 15px;
    .item {
      position: relative;
      padding: 8px 15px;
      margin-bottom: 12px;
      background: #f4f4f4;
      border-radius: 4px;
      color: #666;
      .icon-add {
        margin-right: 10px;
        width: 18px;
        height: 18px;
        background: url("./assets/icon-0.png") center no-repeat;
        background-size: contain;
      }
      .icon {
        width: 28px;
        height: 28px;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .file {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
  .b3 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: dashed px(1) #f1f1f1;
    .btn {
      display: block;
      border: 0;
      width: 100%;
      height: px(50);
      line-height: px(50);
      font-size: px(16);
      background: none;
      text-align: center;
      border: 0;
      &:after {
        display: none;
      }
    }
  }
}
</style>
